.App {
  text-align: center;
  border: 0;
  margin: 0;
  padding: 10px;
}



.bg-image {
  /* The image used */
  background-image: url("../public/mountain.jpg");

  /* Full height */
  min-height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.calendar {
  background-color: white;
  border-radius: 10px;
}
